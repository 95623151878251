import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TitlePage from "../components/templates/TitlePage"

const TitlePagePage = () => (

  <Layout>
    <SEO title="Title Page" />
    <div><Link to="/">Go back to the homepage</Link></div>
    <TitlePage
      date='March 2020'
      practice='ABC Animal Hospital'
      region='TX'
    />
  </Layout>
)

export default TitlePagePage
