import React, { useState } from "react"
import styled from "styled-components"

import fonts from "../../fonts/fonts.css"

import vsLogo from "../../images/powered-by-grey.svg"
import dogCatGrey from "../../images/dog-cat-grey.svg"
import dogCatGreen from "../../images/dog-cat-green.svg"
import dogGrey from "../../images/dog-grey.svg"
import dogGreen from "../../images/dog-green.svg"
import catGrey from "../../images/cat-grey.svg"
import catGreen from "../../images/cat-green.svg"

const TitlePage = ({date, practice, region}) => {
  const [type, setType] = useState('');

  return (
    <TitlePageContainer>
      <Header>
        <div>
          <Title>Compliance Tracker</Title>
          <Subtitle>{date}</Subtitle>
        </div>
        <Logo src={vsLogo}/>
      </Header>
      <Description>
        <Name>{practice}</Name>
        <Region>{'Region - ' + region}</Region>
      </Description>
      <SelectText>Select a species:</SelectText>
      <SelectImage src={type === 'dogCat' ? dogCatGreen : dogCatGrey}
                   selected={type === 'dogCat'} onClick={() => setType('dogCat')}/>
      <SelectImage src={type === 'dog' ? dogGreen : dogGrey}
                   selected={type === 'dog'} onClick={() => setType('dog')}/>
      <SelectImage src={type === 'cat' ? catGreen : catGrey}
                   selected={type === 'cat'} onClick={() => setType('cat')}/>
    </TitlePageContainer>
  )
}

export default TitlePage;

const TitlePageContainer = styled.div`
  max-width: 1170px;
  margin: auto;
  padding 0 15px;
  font-family: HelveticaNeueLTStd, sans-serif;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 30px;
  padding: 40px 0;
  color: #8EC641;
  border-top: solid 0.5pt #3B3B3B;
  border-bottom: solid 6pt #DEDCDB;
`

const Title = styled.div`
  font-size: 60px;
  font-weight: bold;
`

const Subtitle = styled.div`
  font-size: 16px;
`

const Logo = styled.img`
  width: 200px;
`

const Description = styled.div`
  padding: 120px 0;
  color: #3B3B3B;
  font-weight: bold;
`

const Name = styled.div`
  margin-bottom: 10px;
  font-size: 40px;
`

const Region = styled.div`
  font-size: 16px;
`

const SelectText = styled.div`
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`

const SelectImage = styled.img`
  height: 100px;
  cursor: pointer;
  border: 1px solid #8EC641;
  border: ${props => props.selected ? '1px solid #8EC641' : '1px solid #FFF'};
`
